import { APhotoUploadTask } from "@/api/UploadApi";
import useSupportedPdfIdentifiers from '@/components/Pdf/useSupportedPdfIdentifiers';
import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
  useIdentifierNodeFinder,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { useStore } from '@/composables/useTypedStore';
import useUser from '@/composables/useUser';
import { Frage } from '@/models/ba/Frage';
import { Fragenblock } from '@/models/ba/Fragenblock';
import {
  BaNode,
  sortArrayPosition,
  ToJsonSettings,
} from '@/models/ba/interfaces/IBaNode';
import {
  BestandsaufnahmeFlatJson,
  BestandsaufnahmeJson,
  HzbaStatus,
  HzbaStatusKey,
} from '@/models/ba/interfaces/IBestandsaufnahme';
import { FragenblockJson } from '@/models/ba/interfaces/IFragenblock';
import {
  FiredMaengelFilterSettings,
  MangelzuordnungJson,
} from '@/models/ba/interfaces/IMangelzuordnung';
import { MaengelReport } from '@/models/ba/MaengelReport';
import { Mangelzuordnung } from '@/models/ba/Mangelzuordnung';
import ErweitertesMedium from '@/models/erweitertes-medium.model';
import Unterschrift from '@/models/hzba/unterschrift.model';
import Immobilie from "@/models/immobilie.model";
import Localization from "@/models/localization.model";
import { APhoto } from '@/models/photo/a-photo.model';
import StrapiMedia from '@/models/photo/strapi-media.model';
import User from '@/models/user';
import getLocalization from '@/utilities/get-localization';
import { instanceOfPhoto } from "@/utilities/get-media-url";
import { Device } from '@capacitor/device';
import { generateUUID } from "@ionic/cli/lib/utils/uuid";

export default class Bestandsaufnahme
  implements BaNode, BestandsaufnahmeFlatJson
{
  public id!: number;
  public bilder!: APhoto[];
  public malus!: string;
  public malusColor!: string;
  public kommentar!: string;
  public begehungsdatum!: string;
  public bericht!: StrapiMedia;
  public berichts!: ErweitertesMedium[];
  public mangellists: any;
  public archive!: StrapiMedia;
  public matterportLink?: string;
  public immobilie!: number;
  public status!: HzbaStatusKey;
  public isLocal!: boolean;
  public hasUnsavedChanges!: boolean;
  public hasLocalImages!: boolean;
  public isDownloaded!: boolean;
  public unterschrifts?: Unterschrift[];
  public auftraggeber!: string;
  public technProjektsteuerungName!: string;
  public reihenfolge!: string;
  public technProjektsteuerungUnterschrift!: APhoto[];
  public freigabeDatum!: string;
  public fazit!: string;
  public createdAt!: string;
  public updatedAt!: string;
  public createdBy!: string;
  public gutachter!: any;
  public updatedBy?: User;
  public bearbeitenderNutzer?: User;
  public bearbeitendesGeraetId?: string;
  public name: Localization;
  public nameBericht: Localization;
  public nameMehrzahl: Localization;
  public identifier!: string;
  public removedMangelsFromMigrations?: any;
  public fragenblocks?: Fragenblock[] = [];
  public surveyType!: string;
  public areMinimumFragenblocksSet?: boolean;

  constructor(json: BestandsaufnahmeJson, mode?: number) {
    this.id = json.id;
    this.name = json.name;
    this.nameBericht = json.nameBericht;
    this.nameMehrzahl = json.nameMehrzahl;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.identifier = json.identifier;
    this.bilder = json.bilder;
    this.malus = json.malus;
    this.malusColor = json.malusColor;
    this.kommentar = json.kommentar;
    this.begehungsdatum = json.begehungsdatum;
    this.berichts = json.berichts;
    this.mangellists = json.mangellists;
    this.archive = json.archive;
    this.immobilie = json.immobilie;
    this.gutachter = json.gutachter;
    this.status = json.status;
    this.isLocal = json.isLocal;
    this.hasUnsavedChanges = json.hasUnsavedChanges;
    this.hasLocalImages = json.hasLocalImages;
    this.isDownloaded = json.isDownloaded;
    this.unterschrifts = json.unterschrifts;
    this.auftraggeber = json.auftraggeber;
    this.technProjektsteuerungName = json.technProjektsteuerungName;
    this.reihenfolge = json.reihenfolge;
    this.technProjektsteuerungUnterschrift =
      json.technProjektsteuerungUnterschrift;
    this.freigabeDatum = json.freigabeDatum;
    this.fazit = json.fazit;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.createdBy = json.createdBy;
    this.updatedBy = json.updatedBy;
    this.bearbeitenderNutzer = json.bearbeitenderNutzer;
    this.bearbeitendesGeraetId = json.bearbeitendesGeraetId;
    this.removedMangelsFromMigrations = json.removedMangelsFromMigrations
    this.surveyType = json.surveyType;
    this.areMinimumFragenblocksSet = json.areMinimumFragenblocksSet;
    this.id = json.id;
    this.fragenblocks = json.fragenblocks
      ?.sort(sortArrayPosition)
      .map((el: FragenblockJson) => new Fragenblock(el, json.identifier, mode));
  }

  /**
   *
   * Locked helper functions
   *
   */
  public isLockableByGutachter() {
    return (
      this.hasStatus(HzbaStatus.ANGELEGT) ||
      this.hasStatus(HzbaStatus.GEPLANT) ||
      this.hasStatus(HzbaStatus.IN_DURCHFUEHRUNG)
    );
  }
  public isInProgress() {
    return (
      this.hasStatus(HzbaStatus.ANGELEGT) ||
      this.hasStatus(HzbaStatus.GEPLANT) ||
      this.hasStatus(HzbaStatus.IN_DURCHFUEHRUNG)
    );
  }
  public isLocked() {
    return this.bearbeitendesGeraetId || this.bearbeitenderNutzer;
  }
  public async isLockedByCurrentDeviceAndUser() {
    return Bestandsaufnahme.isLockedByCurrentDeviceAndUser(this);
  }
  public static async isLockedByCurrentDeviceAndUser(ba: any) {
    const store = useStore();
    const identifier = store.state.app.deviceId;
    const { user } = useUser();

    if (isNaN(ba.bearbeitenderNutzer)) {
      return (
        ba.bearbeitenderNutzer?.id === user.value?.id &&
        ba.bearbeitendesGeraetId === identifier
      );
    }
    return (
      ba.bearbeitenderNutzer === user.value?.id &&
      ba.bearbeitendesGeraetId === identifier
    );
  }

  public isLockedByForeignUser(currentUser: User) {
    return this.bearbeitenderNutzer?.id !== currentUser.id;
  }
  public isLockedBySameUser(currentUser: User) {
    return this.bearbeitenderNutzer?.id === currentUser.id;
  }
  public async isLockedByForeignDevice() {
    const { identifier } = await Device.getId();
    return this.bearbeitendesGeraetId === identifier;
  }

  public hasPdfSupport() {
    const { isBaPdfSupported } = useSupportedPdfIdentifiers();
    return isBaPdfSupported(this);
  }

  /**
   *
   * Status
   *
   */
  public hasStatus(status: any) {
    return status == HzbaStatus[this.status];
  }

  public getTotalProgress(): number | undefined {
    let blockProgressSum = 0;
    const blocksAmount = this.fragenblocks?.length || 0;
    const blockProgress: any[] = [];
    this.fragenblocks?.forEach((el) => {
      let prog = el.getProgress();
      if (prog === undefined) {
        prog = 0;
      }

      blockProgressSum += prog;
      blockProgress.push({ name: el.getTitle(), progress: el.getProgress() });
    });

    if (blocksAmount === 0) {
      return undefined;
    }

    const progress = blockProgressSum / blocksAmount;
    return progress;
  }

  public findSectionByIdentifier(identifier: string): Fragenblock | undefined {
    let section;
    if (identifier) {
      section = this.fragenblocks?.find((el) => {
        return el.identifier === identifier;
      });
    }
    if (!section) {
      section = this.fragenblocks && this.fragenblocks[0];
    }
    return section;
  }

  public findSectionByMapGeometryConfig(): Fragenblock | null {
    //@ts-ignore
    const section = this.fragenblocks?.find(item => item.config.isMapParent === true) as Fragenblock || null

    return section
  }

  public getFiredMaengel(settings?: FiredMaengelFilterSettings,): Mangelzuordnung[] {
    const maengel: Mangelzuordnung[] = [];
    this.fragenblocks?.forEach((el) =>
      maengel.push(...el.getFiredMaengel(settings)),
    );
    return maengel;
  }

  public async getFiredMaengelJson(): Promise<MangelzuordnungJson[]> {
    return Promise.all(this.getFiredMaengel().map(async (el) => await el.toClassJson()));
  }

  public maengelReport(settings?: FiredMaengelFilterSettings) {
    return new MaengelReport(this.getFiredMaengel(settings));
  }

  public getFrages(): Frage[] {
    const frages: Frage[] = [];
    this.fragenblocks?.forEach((el) => frages.push(...el.getFrages()));
    return frages;
  }

  public findNodeByRelativePath(path: String): Frage | Fragenblock | undefined {
    return useIdentifierNodeFinder(this, this.identifier + '.' + path);
  }
  public findFragenblockByRelativePath(path: String): Fragenblock | undefined {
    return useIdentifierFragenblockFinder(this, this.identifier + '.' + path);
  }
  public findFrageByRelativePath(path: String): Frage | undefined {
    return useIdentifierFrageFinder(this, this.identifier + '.' + path);
  }

  public filterPhotos(filterFctn: Function): APhoto[] {
    const photos: APhoto[] = [];
    this.fragenblocks?.forEach((el) =>
      photos.push(...el.filterPhotos(filterFctn)),
    );
    return photos;
  }

  public getName(lang?: string): string {
    return getLocalization(this.name, lang);
  }


  public getMediaUploadTasks(immobilie: Immobilie): APhotoUploadTask[] {
    if (!this.unterschrifts) {
      return [];
    }

    const arr: APhotoUploadTask[] = [];
    this.unterschrifts?.forEach(el => {
      el.uid = el.uid ? el.uid : generateUUID();

      if (instanceOfPhoto(el.bild) && el.bild) {
        arr.push({
          field: "bild",
          image: el.bild,
          ref: "unterschrift",
          api: "api::unterschrift.unterschrift",
          uid: el.uid,
          status: "preparing",
          instanceId: this.id,
          fileName: `${immobilie?.strasse}_${immobilie?.plz}_${immobilie?.stadt}_${this.identifier}_unterschrift_${this.name}.jpeg`
        })
      }
    })

    console.log("UNTERSCHRIFT", this.unterschrifts)
    return arr;
  }

  public async toClassJson(settings: ToJsonSettings = {}): Promise<BestandsaufnahmeJson> {
    const fragenblockJson = this.fragenblocks && await Promise.all(this.fragenblocks.map(async (el: Fragenblock) =>
      el.copyJson(settings),
    ));

    const sanitizedUnterschrifts = !settings.prepareForSync
      ? this.unterschrifts
      : this.unterschrifts?.map((el) => {
          return {
            id: el.id,
            name: el.name,
            uid: el.uid,
          };
        });

    return {
      id: this.id,
      name: this.name,
      nameMehrzahl: this.nameMehrzahl,
      nameBericht: this.nameBericht,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      identifier: this.identifier,
      fragenblocks: fragenblockJson,
      bilder: this.bilder,
      malus: this.malus,
      malusColor: this.malusColor,
      kommentar: this.kommentar,
      begehungsdatum: this.begehungsdatum,
      berichts: this.berichts,
      mangellists: this.mangellists,
      archive: this.archive,
      matterportLink: this.matterportLink,
      status: this.status,
      unterschrifts: sanitizedUnterschrifts,
      auftraggeber: this.auftraggeber,
      technProjektsteuerungName: this.technProjektsteuerungName,
      technProjektsteuerungUnterschrift: this.technProjektsteuerungUnterschrift,
      reihenfolge: this.reihenfolge,
      freigabeDatum: this.freigabeDatum,
      isLocal: this.isLocal,
      hasUnsavedChanges: this.hasUnsavedChanges,
      hasLocalImages: this.hasLocalImages,
      isDownloaded: this.isDownloaded,
      gutachter: this.gutachter,
      fazit: this.fazit,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      bearbeitenderNutzer: this.bearbeitenderNutzer,
      bearbeitendesGeraetId: this.bearbeitendesGeraetId,
      immobilie: this.immobilie,
      removedMangelsFromMigrations: this.removedMangelsFromMigrations,
      surveyType: this.surveyType,
      areMinimumFragenblocksSet: this.areMinimumFragenblocksSet
    };
  }

  public async toLeanJson(settings: ToJsonSettings = {}): Promise<any> {
    const fragenblockJson = this.fragenblocks && await Promise.all(this.fragenblocks.map(async (el: Fragenblock) =>
      await el.copyLeanJson(settings),
    ));

    const sanitizedUnterschrifts = !settings.prepareForSync
      ? this.unterschrifts
      : this.unterschrifts?.map((el) => {
          return {
            id: el.id,
            name: el.name,
            uid: el.uid,
          };
        });

    return {
      id: this.id,
      identifier: this.identifier,
      fragenblocks: fragenblockJson,
      bilder: this.bilder,
      malus: this.malus,
      malusColor: this.malusColor,
      kommentar: this.kommentar,
      status: this.status,
      unterschrifts: sanitizedUnterschrifts,
      isLocal: this.isLocal,
      hasUnsavedChanges: this.hasUnsavedChanges,
      fazit: this.fazit,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      bearbeitenderNutzer: this.bearbeitenderNutzer,
      bearbeitendesGeraetId: this.bearbeitendesGeraetId,
      berichts: this.berichts,
      mangellists: this.mangellists,
      immobilie: this.immobilie,
      removedMangelsFromMigrations: this.removedMangelsFromMigrations,
      areMinimumFragenblocksSet: this.areMinimumFragenblocksSet
    };
  }

  /**
   * Deep copy this class with all of it's subobjects.
   */
  public async copyJson(settings: ToJsonSettings = {}): Promise<BestandsaufnahmeJson> {
    return JSON.parse(JSON.stringify(await this.toClassJson(settings)));
  }

  public async copyLeanJson(settings: ToJsonSettings = {}): Promise<BestandsaufnahmeJson> {
    return JSON.parse(JSON.stringify(await this.toLeanJson(settings)));
  }

  public async toString(): Promise<string> {
    return JSON.stringify(await this.toClassJson(), undefined, 2);
  }
}
