import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "ml-4 mt-4 flex-1" }
const _hoisted_3 = { class: "md:flex" }
const _hoisted_4 = { class: "flex-1 text-left" }
const _hoisted_5 = {
  "ion-text": "",
  class: "mt-0 mb-0",
  color: "primary"
}
const _hoisted_6 = { class: "mb-0 flex items-center" }
const _hoisted_7 = { class: "mt-2 mb-0 flex items-center" }
const _hoisted_8 = { class: "md:justify-between m-4 mt-4" }
const _hoisted_9 = { class: "flex items-end flex-wrap" }
const _hoisted_10 = { class: "btn-secondary inline-flex items-center mr-2.5 px-4 py-1.5 rounded-full text-sm font-medium text-blue-600 statusbutton" }
const _hoisted_11 = {
  key: 0,
  class: "mr-2"
}
const _hoisted_12 = {
  key: 1,
  class: "mr-2"
}
const _hoisted_13 = { class: "downloadPart my-4" }
const _hoisted_14 = { class: "text-left mt-2 sm:mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_PdfChip = _resolveComponent("PdfChip")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_DownloadSurveyButton = _resolveComponent("DownloadSurveyButton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.ba && _ctx.immobilie)
    ? (_openBlock(), _createBlock(_component_ion_card, {
        key: 0,
        class: _normalizeClass(["hzbaCard", `${
      !_ctx.user.isImmobilienverwalter() ? 'cursor-pointer' : ''
    } m-0 rounded-sm bg-white acon-card-shadow`]),
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToBa($event)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_image, {
              "image-class": 'w-full h-44 object-cover md:block',
              src: _ctx.immobilie && _ctx.getPhotoUrl(_ctx.immobilie.vorschaubild, { small: true })
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.ba.getName && _ctx.ba.getName()), 1),
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.immobilie && _ctx.immobilie.externeObjektNr) + " (" + _toDisplayString(_ctx.moment(_ctx.ba.begehungsdatum).format("YYYY")) + ") ", 1),
                  _createElementVNode("p", _hoisted_6, [
                    _createVNode(_component_IonIcon, {
                      icon: _ctx.locationOutline,
                      class: "mr-1"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.immobilie && ( _ctx.immobilie.strasse ? _ctx.immobilie.strasse + "," : "" )) + " " + _toDisplayString(_ctx.immobilie && _ctx.immobilie.plz) + " " + _toDisplayString(_ctx.immobilie && _ctx.immobilie.stadt), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_7, [
                      _createVNode(_component_IonIcon, {
                        icon: _ctx.calendarOutline,
                        class: "mr-1"
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.moment(_ctx.ba.begehungsdatum).format("DD.MM.YYYY")) + " ", 1),
                      _createVNode(_component_IonIcon, {
                        icon: _ctx.timeOutline,
                        class: "ml-3 mr-1"
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.moment(_ctx.ba.begehungsdatum).format("HH:mm")), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.ba.malus)
                ? (_openBlock(), _createBlock(_component_PdfChip, {
                    key: 0,
                    class: "mr-2",
                    value: _ctx.ba.malus,
                    color: _ctx.malusColors[_ctx.ba.malusColor],
                    big: true
                  }, null, 8, ["value", "color"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_10, [
                (!_ctx.HzbaStatus[_ctx.ba.status])
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Status unbekannt "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.translatedHzbaStatus(_ctx.ba.status, _ctx.t)), 1)),
                (_ctx.HzbaStatus.ANGELEGT == _ctx.HzbaStatus[_ctx.ba.status])
                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                      key: 2,
                      icon: _ctx.ellipseOutline,
                      size: "small"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                (
              _ctx.HzbaStatus.GEPLANT == _ctx.HzbaStatus[_ctx.ba.status] ||
                _ctx.HzbaStatus.IN_DURCHFUEHRUNG == _ctx.HzbaStatus[_ctx.ba.status]
            )
                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                      key: 3,
                      icon: _ctx.ellipsisHorizontalCircle,
                      size: "small"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                (
              _ctx.HzbaStatus.ABGESCHLOSSEN == _ctx.HzbaStatus[_ctx.ba.status] ||
                _ctx.HzbaStatus.FREIGEGEBEN == _ctx.HzbaStatus[_ctx.ba.status] || 
                _ctx.HzbaStatus.ARCHIVIERT == _ctx.HzbaStatus[_ctx.ba.status]
            )
                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                      key: 4,
                      icon: _ctx.checkmarkCircle,
                      size: "small"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                (!_ctx.HzbaStatus[_ctx.ba.status])
                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                      key: 5,
                      icon: _ctx.alertCircleOutline,
                      size: "small"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.alreadyInIndexDB)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "alreadyInIndexDB",
                    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openToast())),
                    onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeToast())),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => {$event.stopPropagation(); _ctx.closeToast(); _ctx.removeFromIndexDB()})
                  }, [
                    _createVNode(_component_ion_icon, { icon: _ctx.arrowDownCircle }, null, 8, ["icon"])
                  ], 32))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_ctx.featureFlags.downloadSurvey && !_ctx.alreadyInIndexDB)
                ? (_openBlock(), _createBlock(_component_DownloadSurveyButton, {
                    key: 0,
                    "is-downloading": _ctx.isDownloading,
                    onOnClick: _ctx.downloadBestandsaufnahme
                  }, null, 8, ["is-downloading", "onOnClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.recommendedBericht(_ctx.ba))
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    "btn-secondary": true,
                    class: "w-full p-2 h-10 text-base",
                    shape: "round",
                    "data-cy": "button-berichtAnzeigen",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openBericht($event, _ctx.recommendedBericht(_ctx.ba))))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.berichtAnzeigen")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.relevantMaengellist && _ctx.relevantMaengellist.length > 0)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    "btn-secondary": true,
                    class: "w-full mb-1 text-base",
                    shape: "round",
                    "data-cy": "button-mangellisteAnzeigen",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openMaengelInImmobilie($event)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("immobilie.buttons.mangellisteAnzeigen")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", {
            class: "flex cursor-pointer p-4 justify-end",
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($event.stopPropagation(), _ctx.handleBaShare()))
          }, [
            _createVNode(_component_IonIcon, {
              size: "small",
              icon: _ctx.shareSocialOutline
            }, null, 8, ["icon"])
          ])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}