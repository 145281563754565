import useBestandsaufnahmeSockets from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSockets";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { getCache, removeCache } from "@/store/storage/ionicStorage";
import { Network } from "@capacitor/network";
import { toastController } from "@ionic/vue";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useBestandsaufnahmens } from "./useBestandsaufnahmens";
import { environment } from '../../../environments/environment'

interface HzbaSubpage {
    onIonViewWillEnter: Function;
    onIonViewWillLeave?: Function;
    // onIonViewDidLeave: Function;
}

export default function useBestandsaufnahmeSubpage(params: HzbaSubpage) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute()
    const user = computed(() => useUser().user.value)
    const currentProject = computed(() => store.state.user.currentUserProject)
    const { downloadBa } = useBestandsaufnahmens()
    const socketBa = useBestandsaufnahmeSockets();
    
    let alert: any;

    const { t } = useI18n({ useScope: 'global' })

    const localSubpage = ref();

    const baId = computed<string>(() => { return router.currentRoute.value.params.baId && router.currentRoute.value.params.baId.toString(); })
    const baSubpageName = computed(() => { return localSubpage.value === undefined ? router.currentRoute.value.params.subpage : localSubpage.value; })

    const ba = computed<Bestandsaufnahme | undefined>(() => {
        return store.state.currentHzba.currentBa;
    })

    const isHzbaLoaded = computed<boolean>(() => !!ba.value?.identifier)

    const immobilie = computed(() => { return ba.value && Immobilie.find(ba.value.immobilie); });

    const goBackUrl = computed(() => {
        const rec = store.state.app.recentRoute;

        if (rec.path && rec.path !== '/') {
            return rec.path;
        } else {
            return `/ba/${baId.value}`
        }
    })

    const generateBaShareLink = (baId: string) => {
        const serverUrl = environment.SERVER_URL
        const url = serverUrl + `/ba/${baId}`

        return url
    }

    const setCurrentHzba = async () => {
       
        if (!baId.value) {
            console.error('No hzbaId or propertyId given.');
            return
        }

        const id: number = parseFloat(baId.value);
        if (store.state.currentHzba.currentBa && id === store.state.currentHzba.currentBa.id) {
            console.log("Hzba already setup. Return here.")
            return;
        }

        const loaded = computed(() => BestandsaufnahmeModel.getters("loaded"));

        const setBa = async () => {
            const networkStatus = await Network.getStatus();
            try {
                if (networkStatus.connected) {
                    const res = await BestandsaufnahmeModel.api().get(`/bestandsaufnahmes/${id}`, { save: false, params: { projectId: currentProject.value.id } })
                    res.response.data.data.isDownloaded = true;
                    await BestandsaufnahmeModel.insertOrUpdate({ data: (res.getDataFromResponse() as any).data });
                } else {
                    const localBas = await BestandsaufnahmeModel.dispatch("loadPersistedBestandsaufnahmes")
                    const localBa = localBas.find((el: any) => {
                        return el.id === id
                    });
                    if (!localBa ) {
                        const cachedSurveyTemplate = await getCache('selectedSurveyTemplate');
                        const parsedSurveyTemplate = JSON.parse(cachedSurveyTemplate)
                        const cachedProperty = await getCache('selectedSurveyProperty')
                        const selectedTemplate = parsedSurveyTemplate.attributes.vorlage
                        const selectedPropertyId = (JSON.parse(cachedProperty)).id
                        const storeBegehungsdatum = store.state.app.createSurvey.date

                        selectedTemplate.id = id 
                        selectedTemplate.status = 'IN_DURCHFUEHRUNG'
                        selectedTemplate.immobilie = selectedPropertyId
                        selectedTemplate.gutachter = user.value!.id
                        selectedTemplate.isDownloaded = true
                        selectedTemplate.surveyType = selectedTemplate.uid
                        selectedTemplate.hasUnsavedChanges = true
                        selectedTemplate.begehungsdatum = (new Date(storeBegehungsdatum)).toISOString()
                        await BestandsaufnahmeModel.insertOrUpdate({ data: selectedTemplate });
                        await removeCache('selectedSurveyProperty');
                        store.commit('app/setCreateSurveyData', null);
                    }   
                }
            } catch (err) {
                await router.push('/bas')
                console.error("HZBA_CRITICAL_ERROR HZBA_LOGGING (FRONTEND) - Error fetching BA in setBa", err);
            }
            
            const nextCurrentBa = BestandsaufnahmeModel.find(id);

            if (!nextCurrentBa) {
                console.error(`Hzba with id ${baId.value} not found.`)

                const toast = await toastController.create({
                    message: t('toasts.keineBaGefundenMitDieserId'),
                    duration: 2000
                })
                await toast.present();

                await router.push('/bas')
            }
            // IMPORTANT! runs always
            await store.dispatch("currentHzba/setCurrentHzba", nextCurrentBa);

            if (!nextCurrentBa?.areMinimumFragenblocksSet ) {
                if (  networkStatus.connected ) {
                    const copiedBa = await ba.value?.copyLeanJson({prepareForSync: true});
                    // console.log('Copied BA JSON before uploading:', copiedBa);
                    // @ts-ignore
                    copiedBa["areMinimumFragenblocksSet"] = true;
                    const baUploadRes = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${ba.value?.id}?projectId=${currentProject.value.id}`,
                        {data: copiedBa},
                        {save: false},
                    );
            
                    const updatedBaJsonRes = (baUploadRes.getDataFromResponse() as any).data as BestandsaufnahmeJson;
                    const updatedBa = new Bestandsaufnahme(updatedBaJsonRes, undefined);
            
                    updatedBa.isLocal = false;
                    await store.commit('currentHzba/setCurrentBa', updatedBa);
                    await store.dispatch("currentHzba/saveHzbaLocally");
                }
            }
            await downloadBa(undefined, ba.value?.id, ba.value?.immobilie);
            socketBa.subscribeToBestandsaufnahme();
        }

        if (loaded.value) {
            setBa();
        } else {
            watch(() => BestandsaufnahmeModel.getters("loaded"), async (value) => {
                setBa();
            });
        }
    }

    params.onIonViewWillEnter(async () => {
        store.commit("app/hideBottomNavigation");

        if (baId.value.toString() !== store.state.currentHzba.currentBa?.id.toString()) {
            await store.dispatch("currentHzba/setCurrentHzba", null);
            await setCurrentHzba();
        }
    });

    params.onIonViewWillLeave && params.onIonViewWillLeave(async () => {
        alert && alert.value && alert.value.dismiss && alert.value.dismiss();
        socketBa.unsubscribeFromBestandsaufnahme()
        if (!route.path.startsWith("/ba/")) {
            await store.dispatch("currentHzba/setCurrentHzba", null);
        }
        return true;
    });

    return {
        isHzbaLoaded,
        baId,
        hzba: store.state.currentHzba.currentHzba,
        ba,
        baSubpageName,
        immobilie,
        goBackUrl,
        localSubpage,
        generateBaShareLink
    }

}