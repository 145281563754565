import apiClient, { strapiApiUrl } from "@/api/api";
import { HzbaStatusCode } from "@/models/ba/interfaces/IBestandsaufnahme";

async function getTemplates() {
  return apiClient.get(`${strapiApiUrl}/vorlage-bestandsaufnahmes`);
}

async function postSurvey(data: any) {
  return apiClient.post(`${strapiApiUrl}/bestandsaufnahmes`, {
    begehungsdatum: data.date,
    gutachter: data.userId,
    status: HzbaStatusCode.ANGELEGT,
    immobilie: data.property,
    vorlage_bestandsaufnahme: data.type,
  });
}

export default {
  getTemplates,
  postSurvey
}
