<template>
  <info-banner
    v-if="isSocketDisconnected"
    :type="'warning'"
  >
    <slot />
  </info-banner>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "@/composables/useTypedStore";
import InfoBanner from "@/components/Base/InfoBanner";

const store = useStore();

const isSocketDisconnected = computed(() => {
    return store.getters['app/isOnline'] && store.getters['app/isSocketDisconnected'];
});
</script>

<style scoped></style>