import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bed410e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, {
        "is-open": _ctx.isOpen,
        "css-class": "modal-fullscreen",
        onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(false)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbar, {
            title: _ctx.title,
            "enable-desktop-nav": false,
            "data-cy": "toolbar"
          }, {
            leading: _withCtx(() => [
              _createVNode(_component_ion_buttons, {
                slot: "start",
                "data-cy": "ion-buttons"
              }, {
                default: _withCtx(() => [
                  (!_ctx.goBackMode && !_ctx.isReadonly)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        "data-cy": "toolbar-button-back",
                        onClick: _ctx.askForClose
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.close,
                            size: "large"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.goBackMode || _ctx.isReadonly)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        "data-cy": "toolbar-button-back-readonly",
                        onClick: _ctx.askForClose
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.t("buttons.zurueck")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "cta", {}, undefined, true)
              ])
            ]),
            _: 3
          }, 8, ["title"])
        ]),
        _: 3
      }, 8, ["is-open"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }))
}