<template>
  <div 
    v-if="isVisible"
    class="floating-button" 
    ref="floatingButton"
    :style="{ top: `${position.top}px`, left: `${position.left}px` }"
    @mousedown="startDrag"
    @touchstart="startDrag"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

  const props = defineProps({
    isVisible: {
      type: Boolean,
      required: true
    }
  })

  const emit = defineEmits([
    'onClick',
    'update:isVisible'
  ])

  //Variables
  const isDragging = ref(false)
  const dragStarted = ref(false)
  const dragThreshold = 10
  const position = ref({
    top: 80,
    left: window.innerWidth - 80
  })
  const startOffset = ref({
    x: 0,
    y: 0
  })

  //Watchers
  watch(() => props.isVisible, (newValue: boolean) => {
      if(!newValue) {
        removeEventListeners();
      }
    },
    {
      immediate: true
    }
  )

  //Methods
  function startDrag(event: any) {
    isDragging.value = false;
    dragStarted.value = false;
    startOffset.value = {
      x: (event.clientX || event.touches[0].clientX) - position.value.left,
      y: (event.clientY || event.touches[0].clientY) - position.value.top
    };
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchmove', onDrag);
    document.addEventListener('touchend', stopDrag);
  }

  function onDrag(event: any) {
    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;
    const dx = clientX - startOffset.value.x - position.value.left;
    const dy = clientY - startOffset.value.y - position.value.top;

    if (Math.abs(dx) > dragThreshold || Math.abs(dy) > dragThreshold) {
      isDragging.value = true;
      dragStarted.value = true;
    }

    if (isDragging.value) {
      position.value = {
        top: clientY - startOffset.value.y,
        left: clientX - startOffset.value.x
      };
    }
  }

  function stopDrag(event: any) {
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('touchmove', onDrag);
    document.removeEventListener('touchend', stopDrag);

    if (!dragStarted.value) {
      emit('onClick')
      emit('update:isVisible', false);
    }

    isDragging.value = false;
    dragStarted.value = false;
  }

  function removeEventListeners() {
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('touchmove', onDrag);
    document.removeEventListener('touchend', stopDrag);
  }
</script>

<style scoped>
.floating-button {
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: var(--white100);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.15s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.floating-button:active {
  transform: scale(0.75);
}
</style>
