import apiClient from "@/api/api";
import apiErrorHelper from "@/api/apiErrorHelper";
import {
    useBestandsaufnahmeUnlockAlert,
    useBestandsaufnahmeViewAndLockAlert
} from "@/composables/Bestandsaufnahme/useBestandsaufnahmeViewAndLockAlert";
import { useProperties } from "@/composables/Property/useProperties";
import { SortTermActive } from "@/composables/Sort/SortTerms";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { HzbaStatusCode } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from '@/models/ba/models/bestandsaufnahme.model';
import Immobilie from '@/models/immobilie.model';
import { logger } from "@/utilities/logging";
import { copyBaAndMergeWithImmobilie, sortArrayByProperty } from "@/utilities/sortArrayByProperty";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { toastController } from "@ionic/vue";
import axios from "axios";
import { default as QueryString, default as qs } from 'qs';
import { computed, ref, watch } from "vue";


export interface FilterOption {
    name: string,
    id: string,
    resetOtherFilters?: boolean,
    resetSelfWhenOthersActive?: boolean,
    onlyShowWhenOthersActive?: boolean;
    dontShowWhenNotSelected?: boolean;
}

export function useBestandsaufnahmens() {

    const store = useStore();

    const isFetchingBestandsaufnahmes = ref(false);
    const shouldAbortBestandsaufnahme = ref(false);

    /**
     * Bas from vuex. Some of them may be local as well. If we are offline, we only show downloaded
     * as we can only open fully downloaded bas anyway.
     *
     * Filter out id's with $ as our plugin (vuex-orm-localforage?) produces temporary dead ba's when deleting some from localstorage.
     */
    const bestandsaufnahmes = computed(() => {

        const isOffline = computed(() => { return store.getters["app/isOffline"]; });
        const persistedBas = computed<BestandsaufnahmeModel[]>(() => { return BestandsaufnahmeModel.getters('persistedBestandsaufnahmes') })

        const sortBy = BestandsaufnahmeModel.getters("sortBy") as SortTermActive;
        const stateFilters: FilterOption[] = BestandsaufnahmeModel.getters("stateFilter");
        const isLocaleReset = stateFilters.filter(item => item.id === HzbaStatusCode.RESET).length > 0

        const showOfflineOnly = stateFilters?.find(el => el.id === HzbaStatusCode.OFFLINE)

        const searchFilter = (bestandsaufnahme: BestandsaufnahmeModel) => {
            const property = Immobilie.find(bestandsaufnahme.immobilie);

            let searchTerm = BestandsaufnahmeModel.getters('searchTerm');
            searchTerm = searchTerm && searchTerm.toLowerCase();

            const offlineStateFilters = stateFilters?.filter(el => !el.id.startsWith('LOCALE_'))

            return (!searchTerm || Immobilie.getters('propMatchesFilter')(property, searchTerm)) &&
                (!offlineStateFilters || offlineStateFilters.length === 0 || offlineStateFilters.find(el => el.id === bestandsaufnahme.status))
        }

        if (!isOffline.value && !showOfflineOnly) {
            let bas = BestandsaufnahmeModel.all();
            if(stateFilters.length > 0 && !isLocaleReset) {
                bas = bas
                    .filter(el => !el.id.toString().includes('$'))
                    .filter(el => stateFilters.find(item => item.id === el.status))
                    .filter(searchFilter)
                    .sort((a, b) => sortArrayByProperty(copyBaAndMergeWithImmobilie(a), copyBaAndMergeWithImmobilie(b), sortBy));
            } else {
                bas = bas
                .filter(el => !el.id.toString().includes('$'))
                .filter(searchFilter)
                .sort((a, b) => sortArrayByProperty(copyBaAndMergeWithImmobilie(a), copyBaAndMergeWithImmobilie(b), sortBy));
            }
            return bas;
        } 

        const filteredAndSearched = persistedBas.value
            .filter(el => el.isDownloaded && !el.id.toString().includes('$'))
            .filter(searchFilter)
            .sort((a, b) => sortArrayByProperty(copyBaAndMergeWithImmobilie(a), copyBaAndMergeWithImmobilie(b), sortBy));

        return filteredAndSearched;
    });

    const currentProject = computed(() => store.state.user.currentUserProject)

    /**
     * Build the query for the backend.
     */
    const getNextQuery = async () => {
        const pageSize = 25;

        BestandsaufnahmeModel.commit((state) => state.page = (BestandsaufnahmeModel.getters("page") + 1));
        const sortBy = BestandsaufnahmeModel.getters("sortBy");

        const searchString = BestandsaufnahmeModel.getters("searchTerm");
        const stateFilters = BestandsaufnahmeModel.getters("stateFilter");

        const query: any = {
            pagination: {
                page: BestandsaufnahmeModel.getters("page"),
                pageSize: pageSize
            }
        };


        if (sortBy) {
            let sortObj: any;
            if ( sortBy.fieldName=== "malusColor" ) {
                sortObj = [ [sortBy.fieldName] + ":" + sortBy.orderBy, "malus:" + sortBy.orderBy ];
            } else {
                sortObj = { [sortBy.fieldName]: sortBy.orderBy }
            }
            if (sortBy.subObject) {
                query.sort = { [sortBy.subObject]: sortObj }
            } else {
                query.sort = sortObj;
            }
        }

        if ((searchString ?? "") != "") {
            query.filters = {
                $and: [
                    {
                        $or: [
                            { immobilie: { name: { $containsi: searchString, } } },
                            { immobilie: { eigentuemer: { $containsi: searchString } } },
                            { immobilie: { strasse: { $containsi: searchString } } },
                            { immobilie: { plz: { $containsi: searchString } } },
                            { immobilie: { stadt: { $containsi: searchString } } },
                            { immobilie: { externeObjektNr: { $containsi: searchString } } },
                            { immobilie: { baujahr: { $containsi: searchString } } },
                        ]
                    }
                ]
            };
        }

        if (!query.filters) { query.filters = { $and: [] } }
        const backendFilter = stateFilters.filter((el: FilterOption) => !el.id.startsWith('LOCALE'));
        if (backendFilter && stateFilters.length > 0) {
            const stateQuery: any = { $or: [] }
            backendFilter.forEach((el: FilterOption) => {
                stateQuery.$or.push({ status: el.id })
            })
            query.filters.$and.push(stateQuery)
        }else{
            query.filters.$and.push({ status: { $ne: 'ARCHIVIERT' } })
        }

        

        return query;
    }

    /**
     * Stringifies the query
     */
    const getNextQueryString = async () => {
        return QueryString.stringify(await getNextQuery())
    }

    /**
     * Load Bas from IndexedDB but do not store them in vuex directly. We don't know if we want to show those properties at this state due to search/filter function;
     */
    const loadPersistedBestandsaufnahmes = async (): Promise<BestandsaufnahmeModel[]> => {
       return await BestandsaufnahmeModel.dispatch("loadPersistedBestandsaufnahmes");
        // return BestandsaufnahmeModel.getters("persistedBestandsaufnahmes")
    }

    const controller = new AbortController();

    /**
     * Fetch ba's from backend.
     * If we find downloaded but not local edited ba's, we load them fully instead of the preview
     */
    const CancelToken = axios.CancelToken;
    const cancelableSources: any[] = [];

    const loadBestandsaufnahmens = async (query?: string) => {
        console.log("load Bestandsaufnahmes")
        logger.info('Loading BAs ...');
        if (isFetchingBestandsaufnahmes.value) {
            console.log("SOURCE CANCEL");
            logger.warn('SOURCE CANCEL');

            cancelableSources.forEach(el => {
                el.cancel('Operation canceled by the user.')
            })
        }

        // NOTE: in offline-case search result counts are not set here in the composable
        BestandsaufnahmeModel.commit((state) => {         
            state.searchResultsCount = null;
            state.totalSearchResultsCount = null;
        });

        const persistedBas = await loadPersistedBestandsaufnahmes();
        const stateFilter = BestandsaufnahmeModel.getters("stateFilter")
        try {
            const status = await Network.getStatus();
            if(!status.connected || stateFilter.find((filter: any) => filter.id === HzbaStatusCode.OFFLINE)) {
                if(!status.connected) {
                    console.error(`Loading Bestandsaufnahmes failed: Network Error`);
                }

                await BestandsaufnahmeModel.insert({ data: persistedBas });
                BestandsaufnahmeModel.commit((state) => {
                    state.page = 1;
                    state.pageCount = 1;
                    state.loaded = true;
                });
            } else {

                isFetchingBestandsaufnahmes.value = true;
                const source = CancelToken.source();
                cancelableSources.push(source)
            
                const res = await apiClient.get(`/bestandsaufnahmes?${query ? query : await getNextQueryString()}`, {
                    cancelToken: source.token
                });

                const fetchedBackendBaRes = (res.data as any);
                const pagination = fetchedBackendBaRes.pagination;

                const retrievedCount = pagination.page >= pagination.pageCount ? pagination.total : pagination.page * pagination.pageSize;
                BestandsaufnahmeModel.commit((state) => {
                    state.searchResultsCount = retrievedCount
                    state.totalSearchResultsCount = pagination.total
                });

                // Ba's to push.
                const basToPush: BestandsaufnahmeModel[] = [];

                for (const ba of fetchedBackendBaRes.results) {
                    await Immobilie.dispatch('addToFallbackProperties', ba.immobilie);

                    ba.immobilie = ba.immobilie?.id;

                    // Bas with local edits won't be updated.
                    const persistedEditedBa = persistedBas?.find((local: any) => local.id === ba.id && local.isLocal)
                    if (persistedEditedBa) {
                        basToPush.push(persistedEditedBa)
                        continue;
                    }

                    // Downloaded bas will be updated, but fully instead of preview only.
                    const localDownloadedUneditedBa = persistedBas?.find((local: any) => local.id === ba.id);
                    if (localDownloadedUneditedBa) {
                        if (new Date(localDownloadedUneditedBa!.updatedAt) < new Date(ba.updatedAt)) {
                            // new update found from backend. load full ba for this.
                            console.log("DOWNLOAD BA", ba.id)
                            const newDownloadedBa = await downloadBa(undefined, ba.id, ba.immobilie);
                            newDownloadedBa && basToPush.push(newDownloadedBa);
                        } else {
                            basToPush.push(localDownloadedUneditedBa);
                        }
                        continue;
                    }

                    // Ba is neither local edited, nor downloaded and unedited nor should be downloaded
                    basToPush.push(ba);
                }
                await BestandsaufnahmeModel.insert({ data: basToPush });

                // Post status updates
                store.commit('app/updateBestandsaufnahmesLastRefresh');
                BestandsaufnahmeModel.commit((state) => {
                    state.page = fetchedBackendBaRes.pagination.page;
                    state.pageCount = fetchedBackendBaRes.pagination.pageCount;
                    state.loaded = true;
                });

                isFetchingBestandsaufnahmes.value = false;
            }
        } catch (error: any) {
            console.error(error)
            logger.error(`Loading Bestandsaufnahmes failed: ${error}`);
        }
    }

    /**
     * Mark the BA as isDownloaded
     * Mark the immobilie as isDownloaded
     * Store both to indexedDB
     *
     * Either pass ba OR ( baId AND immoId )
     * passing ba makes sense e.g. if you are calling it from currentBa
     */
    const downloadBa = async (ba?: Bestandsaufnahme | undefined, baId?: number, immoId?: number) => {
        if (ba) { logger.defaultMeta.currentBa = ba.id }
        if (baId) { logger.defaultMeta.currentBa = baId }
        if (immoId) { logger.defaultMeta.immoId = immoId }

        if (!ba && !baId) {
            console.error('Cannot download Ba: ba is undefined.')
            logger.error(`Cannot download Ba: ba is undefined`)

            return;
        }

        let baModel = BestandsaufnahmeModel.find(baId || ba!.id);

        // If ba is preview mode, download the full ba.
        if (!baModel || !baModel.fragenblocks || !baModel.fragenblocks.length) {
            const res: any = await BestandsaufnahmeModel.api().get(`/bestandsaufnahmes/${baId || ba!.id}`, { save: false, params: { projectId: currentProject.value.id }})
            baModel = (res.getDataFromResponse() as any).data;
        }

        if (!baModel) {
            console.error(`Cannot download Ba: ba Model is empty`)
            logger.error(`Cannot download Ba: ba model is empty`)

            return;
        }

        baModel.isDownloaded = true;

        await BestandsaufnahmeModel.insertOrUpdate({
            data: baModel,
        });
        // console.log("MYLOG BestandsaufnahmeModel.find", baModel.id, baModel)

        const uBa = await BestandsaufnahmeModel.find(baModel.id);

        if (ba) { ba.isDownloaded = true; }
       
        await BestandsaufnahmeModel.dispatch('$updateLocally', { data: uBa });
        // await BestandsaufnahmeModel.dispatch('addToPersistedBestandsaufnahmes', uBa);

        // at the moment this will never fetch a property as we send immobilies with our ba's and store them into Immobilies.
        const { considerDownloadImmobilie } = useProperties();
        await considerDownloadImmobilie(immoId || ba!.immobilie);

        console.log(`Immobilie ${immoId || ba!.immobilie} downloaded`);

        logger.info(`Immobilie ${immoId || ba!.immobilie} downloaded`);
        return baModel;
    }

    /**
     * Remove downloaded Ba from indexedDb
     * @param ba
     */
    const removeDownloadedBa = async (ba?: Bestandsaufnahme) => {
        const immobilie = ba?.immobilie && Immobilie.find(ba?.immobilie) as (Immobilie | undefined);
        if (!ba) { return console.error('removeDownloadedBa: ba is undefined') }

        const baModel = BestandsaufnahmeModel.find(ba.id);
        if (baModel && immobilie) {
            // baModel.isDownloaded = false;
            ba.isDownloaded = false;

            await BestandsaufnahmeModel.dispatch('$deleteFromLocal', baModel.id);

            await store.dispatch('currentHzba/refetchCurrentBa', currentProject.value.id);

            // with current API it's not possible to  delete only from local storage but not from store, thus we need to insert afterwards again
            console.log("remove downloads add to vuex", await ba.toClassJson());

            // await BestandsaufnahmeModel.dispatch('removeFromPersistedBestandsaufnahmes', baModel);
            await BestandsaufnahmeModel.insertOrUpdate({ data: await ba.toClassJson() });

            const { considerRemoveImmobilieFromDownloaded } = useProperties();
            await considerRemoveImmobilieFromDownloaded(baModel.immobilie)
        }
    }

    /**
     * Lock ba and set the state to "IN_DURCHFUEHRUNG".
     * Does not repull the ba from backend but instead, after a successful lock, sets same variables as from backend side.
     * ba - ba you want to lock.
     */
    const lockBa = async (ba: Bestandsaufnahme, t: any) => {
        // logger.defaultMeta.currentBa = ba.id};

        const { user } = useUser();
        const { identifier } = await Device.getId();

        if (user.value) { logger.defaultMeta.userId = user.value.id; }

        try {
            let toast = await toastController.create({ message: t('toasts.tryLock'), duration: 2000 })
            await toast.present();

            await apiClient.put(`/bestandsaufnahmes/lock/${ba.id}`, {
                "geraeteId": identifier
            });

            const refetchedBa = await store.dispatch('currentHzba/refetchCurrentBa', currentProject.value.id, true);

            refetchedBa.status = "IN_DURCHFUEHRUNG";
            refetchedBa.bearbeitenderNutzer = user.value ?? undefined;
            refetchedBa.bearbeitendesGeraetId = identifier;
            console.log('status: ', refetchedBa.status)
            console.log("Lock ba", refetchedBa)
            logger.info(`BA ${ba.id} locked by device ${identifier}`);

            await downloadBa(refetchedBa);

            toast = await toastController.create({ message: t('toasts.lockSucceeded'), duration: 2000 })
            await toast.present();

        } catch (error) {
            ba.status = "IN_DURCHFUEHRUNG";

            const toast = await toastController.create({ message: t('toasts.lockFailed'), duration: 2000 })
            await toast.present();
            logger.error(`BA ${ba.id} could not be locked: ${error}`);

            console.error(`BA ${ba.id} could not be locked: ${error}, identifier: ${identifier}, user: ${user.value}`);
            throw error;
        }
    }

    /**
     * Unlock ba
     * Does not repull the ba from backend but instead, after a successful lock, sets same variables as from backend side.
     * ba - ba you want to unlock.
     */
    const unlockBa = async (ba: Bestandsaufnahme, t: any) => {
        const { identifier } = await Device.getId();
        const { user } = useUser();

        if (user.value) { logger.defaultMeta.userId = user.value.id; }

        try {
            await apiClient.put(`/bestandsaufnahmes/unlock/${ba.id}`, {
                "geraeteId": identifier
            });

            ba.bearbeitenderNutzer = undefined;
            ba.bearbeitendesGeraetId = undefined;

            await removeDownloadedBa(ba);
            console.info(`BA ${ba.id} unlocked by device ${identifier}`);
            logger.info(`BA ${ba.id} unlocked by device ${identifier}`);

        } catch (error) {
            await apiErrorHelper(error, t);
            console.error(`Unlocking BA by device ${identifier} failed: ${error}`);

            logger.error(`Unlocking BA by device ${identifier} failed: ${error}`);

            throw error;
        }
    }

    const lockAndEditBaAlert = async (ba: Bestandsaufnahme, t: any, abortMode?: boolean) => {
        return await useBestandsaufnahmeViewAndLockAlert(ba, t, abortMode);
    }

    const unlockBaAndDeleteLocalDataAlert = async (ba: Bestandsaufnahme, t: any) => {
        return await useBestandsaufnahmeUnlockAlert(ba, t);
    }

    const doLocalBestandsaufnahmesExists = async () => {
        return BestandsaufnahmeModel.all().find(el => el.isLocal);
    }

    const isUpgradeAvailable = async (ba: Bestandsaufnahme) => {
        const res = await apiClient.get(`/bestandsaufnahmes/isUpgradeAvailable/${ba.id}`);
        return res.data;
    }

    const migrateBa = async (ba: Bestandsaufnahme, preview: Boolean) => {
        const res = await apiClient.put(`/bestandsaufnahmes/upgradeTemplate/${ba.id}`, { preview: preview });
        return res.data
    }


    const getBackupsForBa = async (baId: number) => {
        const query = qs.stringify({
            sort: ['createdAt:desc'],
            filters: {
                bestandsaufnahme: {
                    id: {
                        $eq: baId,
                    },
                },
            },
            fields: ['createdAt'],
            pagination: {
                pageSize: 10,
                page: 1,
            },
            publicationState: 'live',
            locale: ['en'],
        }, { encodeValuesOnly: true });

        const res = await apiClient.get(`/backup-bestandsaufnahmes?${query}`)
        return res.data.data;
    }

    const runBackup = async (backupId: number) => {
        const res = await apiClient.put(`/bestandsaufnahmes/runBackup/${backupId}`)
        return res;
    }

    return {
        bestandsaufnahmes,
        loadBestandsaufnahmens,
        removeDownloadedBa,
        downloadBa,
        unlockBaAndDeleteLocalDataAlert,
        lockAndEditBaAlert,
        lockBa,
        runBackup,
        getBackupsForBa,
        isUpgradeAvailable,
        unlockBa,
        doLocalBestandsaufnahmesExists,
        migrateBa
    };
}







// const loadAndUpdateFromLocalStorage = async (fetched?: BestandsaufnahmeModel[]) => {
//     const { bestandsaufnahmes } = await BestandsaufnahmeModel.dispatch("$fetchFromLocal");
//     bestandsaufnahmes?.forEach(async (ba: BestandsaufnahmeModel) => {
//         if (!ba.isLocal && ba.isDownloaded) {
//             const fetchedBa = fetched?.find(fetchedBa => fetchedBa.id === ba.id);
//             if (fetchedBa) {
//                 if (new Date(fetchedBa!.updatedAt) > new Date(ba.updatedAt)) {
//                     const res = await BestandsaufnahmeModel.api().get(`/bestandsaufnahmes/${ba.id}`, { save: false })
//                     await BestandsaufnahmeModel.dispatch('$updateLocally', { data: (res.getDataFromResponse() as any).data });
//                 }
//             }
//         }
//     })
// }


