import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_wrapper = _resolveComponent("section-wrapper")!
  const _component_RecursiveForm = _resolveComponent("RecursiveForm")!
  const _component_SurveyMapFragenblockButtons = _resolveComponent("SurveyMapFragenblockButtons")!
  const _component_hzba_delete_duplicate_item = _resolveComponent("hzba-delete-duplicate-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_hzba_base_modal = _resolveComponent("hzba-base-modal")!

  return (_openBlock(), _createBlock(_component_hzba_base_modal, {
    title: _ctx.fragenblock && _ctx.fragenblock.getTitle(),
    open: _ctx.open,
    "ask-before-close": _ctx.surveyMapModal ? true : false,
    "go-back-mode": _ctx.surveyMapModal ? false : true,
    onClose: _cache[4] || (_cache[4] = () => _ctx.cancel())
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.fragenblock?.path)
            ? (_openBlock(), _createBlock(_component_section_wrapper, {
                key: 0,
                class: "mt-4 mx-6 text-xs",
                style: {"color":"#7f7f7f"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getNestedBreadcrumbTitle(_ctx.fragenblock.path)), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_section_wrapper, null, {
            default: _withCtx(() => [
              (_ctx.fragenblock)
                ? (_openBlock(), _createBlock(_component_RecursiveForm, {
                    key: 0,
                    "parent-fragenblock": _ctx.fragenblock,
                    "modal-depth": _ctx.modalDepth
                  }, null, 8, ["parent-fragenblock", "modal-depth"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (_ctx.surveyMapModal)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_SurveyMapFragenblockButtons, {
                        onDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteInstance())),
                        onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveInstance()))
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_ctx.fragenblock && _ctx.fragenblock.blockTyp && _ctx.fragenblock.blockTyp.toLowerCase() === 'mehrfach')
                        ? (_openBlock(), _createBlock(_component_hzba_delete_duplicate_item, {
                            key: 0,
                            fragenblock: _ctx.fragenblock,
                            onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteInstance())),
                            onDuplicate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.duplicateInstance()))
                          }, null, 8, ["fragenblock"]))
                        : _createCommentVNode("", true)
                    ]))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "open", "ask-before-close", "go-back-mode"]))
}