<template>
  <ion-page>
    <Toolbar
      :title="t('hzba.pdf.vorschauTitel')"
      show-back-button
      @onBackButtonClicked="() => {}"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button @click="$router.replace(goBackUrl)">
            <ion-icon
              slot="icon-only"
              color="light"
              size="large"
              :icon="arrowBack"
            />
          </ion-button>
        </ion-buttons>
      </template>

      <template #trailing>
        <ion-buttons slot="primary">
          <ion-button
            slot="end"
            color="light"
            :disabled="isDownloading"
            @click="downloadPdf"
          >
            <!--            {{-->
            <!--              !isDownloading-->
            <!--                ? t("hzba.buttons.downloaden")-->
            <!--                : "pdf wird erstellt..."-->
            <!--            }}-->

            <ion-icon
              v-if="!isDownloading"
              slot="icon-only"
              color="light"
              :icon="canShare ? shareSocialOutline : download"
            />
            <ion-spinner
              v-else
              style="color: white"
              color="light"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </Toolbar>
    <ion-content>
      <PdfViewer
        v-if="isPdfDataLoaded"
        ref="pdfViewer"
        :download-name="downloadName" 
        :go-back-url="goBackUrl"
        @download-completed="isDownloading = false"
      >
        <pdf-templates
          :ba="ba"
          :immobilie="immobilie"
        />
      </PdfViewer>
      <PdfPageSkeleton v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import Toolbar from "@/components/Navigation/Toolbar";
import { default as PdfHzbaPreview, default as PdfHzbaTemplate } from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate";
import PdfViewer from "@/components/Pdf/PdfPreviewerAndCreator";
import PdfTemplates from "@/components/Pdf/PdfTemplates";
import PdfPageSkeleton from "@/components/PdfPageSkeleton";
import useBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSubpage";
import useDownloadHelper from "@/composables/useDownloadHelper";
import { useStore } from "@/composables/useTypedStore";
import Immobilie from "@/models/immobilie.model";
import {
IonButton,
IonButtons,
IonContent,
IonIcon,
IonPage,
IonSpinner,
onIonViewWillEnter,
onIonViewWillLeave,
toastController
} from "@ionic/vue";
import { arrowBack, download, shareSocialOutline } from 'ionicons/icons';
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default {
  name: "PdfPreview",
  components: {
    PdfTemplates,
    PdfPageSkeleton,
    Toolbar,
    IonIcon,
    IonButtons,
    IonSpinner,
    IonButton,
    PdfHzbaTemplate,
    PdfHzbaPreview,
    IonContent,
    PdfViewer,
    IonPage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    const pdfViewer = ref();
    const store = useStore();
    const router = useRouter();
    const isDownloading = ref(false);

    const { canShare } = useDownloadHelper();

    const { isHzbaLoaded, ba, goBackUrl } = useBestandsaufnahmeSubpage({
      onIonViewWillEnter,
      onIonViewWillLeave,
    });

    // console.log("ba.value.status:", ba.value.status);
    // console.log(
    //   "ba.value.technProjektsteuerungName:",
    //   ba.value.technProjektsteuerungName
    // );

    watch(
      () => isHzbaLoaded.value,
      async (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
          if (!ba.value.hasPdfSupport()) {
            const toast = await toastController.create({
              message: t("hzba.pdf.pdfErstellungNichtSupported"),
              duration: 2000,
            });
            await toast.present();

            await router.replace(goBackUrl.value);
          }
        }
      }
    );

    const immobilie = computed(() => {
        return Immobilie.getters('getPropOrFallbackProp')( ba.value.immobilie );
    });

    const isPdfDataLoaded = computed(() => {
      return isHzbaLoaded.value && immobilie.value;
    });

    const downloadName = computed(() => {
      return (immobilie.value?.stadt + "_" + immobilie.value?.name + "_" + ba.value?.name?.de + "_vorschau.pdf").replace(' ', '_');
    });

    const downloadPdf = async () => {
      isDownloading.value = true;
      await nextTick();

      await pdfViewer.value.downloadPdf();
      isDownloading.value = false
    };

    return {
      ba,
      arrowBack,
      immobilie,
      canShare,
      isHzbaLoaded,
      downloadPdf,
      shareSocialOutline, download,
      pdfViewer,
      goBackUrl,
      isDownloading,
      t,
      downloadName,
      isPdfDataLoaded
    };
  },
};
</script>

<style scoped>
</style>