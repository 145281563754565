import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f508e00a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-1" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex items-center w-full justify-end flex-wrap" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 3,
  class: "absolute bottom-1 right-1",
  style: {"width":"8px","height":"8px","border-radius":"50%","background":"green"}
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 3,
  class: "pl-2 pr-2",
  "data-cy": "photo-add-button-text"
}
const _hoisted_9 = {
  key: 0,
  class: "flex justify-end mt-2 tc-grey-500 text-base mr-2"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_CameraIcon = _resolveComponent("CameraIcon")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "none",
    class: "flex py-2",
    style: {"background":"var(--white100)","flex-wrap":"wrap","flex-shrink":"1","display":"flex","flex-grow":"1"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, { class: "p-0 w-full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "px-0" }, {
            default: _withCtx(() => [
              (_ctx.label)
                ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.label), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_col, { class: "p-0" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (photo, index) => {
                        return (_openBlock(), _createBlock(_component_ion_thumbnail, {
                          key: index,
                          class: "image-preview relative",
                          style: {"flex-shrink":"0","display":"inline-block"},
                          "data-cy": "image-preview"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isImageUploadType)
                              ? (_openBlock(), _createBlock(_component_a_image, {
                                  key: 0,
                                  src: _ctx.getPhotoUrl(photo, { thumbnail: true }),
                                  "image-class": 'w-full h-full object-cover rounded-md',
                                  "data-cy": "image",
                                  onClick: ($event: any) => (_ctx.openGallery(index))
                                }, null, 8, ["src", "onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.isPdfUploadType)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: "w-full h-full object-cover rounded-md flex justify-center items-center",
                                  style: {"background":"#efefef"},
                                  onClick: ($event: any) => (_ctx.openPdfViewer(index))
                                }, " .pdf ", 8, _hoisted_4))
                              : _createCommentVNode("", true),
                            (_ctx.isOtherUploadType)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: "w-full h-full object-cover rounded-md flex justify-center items-center",
                                  style: {"background":"#efefef"},
                                  onClick: ($event: any) => (_ctx.downloadInputFile(photo))
                                }, " file ", 8, _hoisted_5))
                              : _createCommentVNode("", true),
                            (!_ctx.instanceOfPhoto(photo))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      (!_ctx.readonly && !_ctx.isReadonly && !_ctx.maxAllowed())
                        ? (_openBlock(), _createBlock(_component_AButton, {
                            key: 0,
                            "btn-primary": true,
                            "data-cy": "button-add-photo",
                            class: _normalizeClass(["flex-shrink-0", [
                  !_ctx.extendedButton && _ctx.isPdfUploadType ? 'pdf-button' : ''
                ]]),
                            style: _normalizeStyle(
                  _ctx.isOtherUploadType || _ctx.isPdfUploadType ? 'padding-top: 12px; padding-bottom: 12px ' : ''),
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTakePhoto()))
                          }, {
                            default: _withCtx(() => [
                              (!_ctx.extendedButton && _ctx.isImageUploadType)
                                ? (_openBlock(), _createBlock(_component_CameraIcon, { key: 0 }))
                                : _createCommentVNode("", true),
                              (!_ctx.extendedButton && ( _ctx.isOtherUploadType || _ctx.isPdfUploadType))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "+"))
                                : _createCommentVNode("", true),
                              (_ctx.extendedButton && _ctx.isImageUploadType)
                                ? (_openBlock(), _createBlock(_component_CameraIcon, { key: 2 }))
                                : _createCommentVNode("", true),
                              (!((_ctx.modelValue?.length ?? 0) > 0) && _ctx.extendedButton)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.isPdfUploadType ? _ctx.t("hzba.buttons.pdfHinzufuegen") : _ctx.t("hzba.buttons.hinzufuegen")), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["class", "style"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  (_ctx.minMax)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_ctx.minMax.min === _ctx.minMax.max)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, " genau " + _toDisplayString(_ctx.minMax.min), 1))
                          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              (_ctx.minMax.min || _ctx.minMax.max)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "("))
                                : _createCommentVNode("", true),
                              (_ctx.minMax.min)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_13, "min. " + _toDisplayString(_ctx.minMax.min), 1))
                                : _createCommentVNode("", true),
                              (_ctx.minMax.min && _ctx.minMax.max)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, " / "))
                                : _createCommentVNode("", true),
                              (_ctx.minMax.max)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_15, "max. " + _toDisplayString(_ctx.minMax.max), 1))
                                : _createCommentVNode("", true),
                              (_ctx.minMax.min || _ctx.minMax.max)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_16, ")"))
                                : _createCommentVNode("", true)
                            ]))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_input_error, { errors: _ctx.errors }, null, 8, ["errors"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}