import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-end gap-4 mini" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AImage = _resolveComponent("AImage")!
  const _component_IonCardSubtitle = _resolveComponent("IonCardSubtitle")!
  const _component_IonCardTitle = _resolveComponent("IonCardTitle")!
  const _component_IonCardHeader = _resolveComponent("IonCardHeader")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonImg = _resolveComponent("IonImg")!
  const _component_IonCardContent = _resolveComponent("IonCardContent")!
  const _component_IonCard = _resolveComponent("IonCard")!

  return (_openBlock(), _createBlock(_component_IonCard, {
    class: "propertyCard",
    onClick: _ctx.cardClicked
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AImage, {
        "image-class": 'w-full object-cover md:block '+ (_ctx.isSlideMode?'h-20':'h-44'),
        src: _ctx.getPhotoUrl(_ctx.property.vorschaubild, { small: true }),
        "set-to-max": ""
      }, null, 8, ["image-class", "src"]),
      _createVNode(_component_IonCardHeader, {
        class: _normalizeClass(_ctx.isSlideMode?'smallerHeader':'')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IonCardSubtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.property.strasse) + " " + _toDisplayString(_ctx.property.plz) + " " + _toDisplayString(_ctx.property.stadt), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_IonCardTitle, {
            class: _normalizeClass(_ctx.isSlideMode?'smallerTitle':'')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.property.externeObjektNr), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_IonCardContent, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default"),
            (_ctx.featureFlags?.property?.changeStatus)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "flex cursor-pointer",
                  title: _ctx.$t('immobilie.updateStatus'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {$event.stopPropagation(); _ctx.changeStatus(_ctx.property, _ctx.t)})
                }, [
                  _createVNode(_component_IonIcon, {
                    size: "large",
                    icon: _ctx.statusIconState
                  }, null, 8, ["icon"])
                ], 8, _hoisted_2))
              : _createCommentVNode("", true),
            (_ctx.featureFlags?.property?.downloadProperty)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  title: _ctx.$t('downloadSurveyButton.download'),
                  class: "flex cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($event.stopPropagation(), _ctx.handleImmobilieDownload()))
                }, [
                  _createVNode(_component_IonIcon, {
                    size: "large",
                    icon: _ctx.downloadIconState
                  }, null, 8, ["icon"])
                ], 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.featureFlags?.property?.changePerson)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "flex personIcon cursor-pointer",
                  title: _ctx.$t('immobilie.updatePerson'),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => ($event.stopPropagation(), _ctx.changePerson(_ctx.property, _ctx.t)))
                }, [
                  (_ctx.property.verwalters && _ctx.property.verwalters[0] && _ctx.property.verwalters[0].profilbild)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_IonImg, {
                          src: _ctx.getPhotoUrl(_ctx.property.verwalters[0].profilbild)
                        }, null, 8, ["src"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.username), 1))
                ], 8, _hoisted_4))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "flex cursor-pointer",
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($event.stopPropagation(), _ctx.handlePropertyShare()))
            }, [
              _createVNode(_component_IonIcon, {
                size: "large",
                icon: _ctx.shareSocialOutline
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["onClick"]))
}