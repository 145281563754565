// for circular dependencies with asynchronous wait times
// e.g. remote connect => fail => reconnect => fail => reconnect => etc.
// uses exponential backoff to retry an API call
type RetryOptions =
    { retries?: number; initialDelay?: number; infinite?: boolean; external?: boolean; };

export const retryHelper = ({ retries = 5, initialDelay = 1000, infinite = false }: RetryOptions
    = { retries: 5, initialDelay: 1000, infinite: false }) => {

    let attempt = 0;

    const retry = async (apiCall: (...args: any) => any, ...args: any[]) => {

        let waitTime = 0;

        if (infinite) {
            waitTime = 5000;
        } else {
            attempt++;
            if (attempt >= retries) {
                attempt = 0;
                throw new Error(`API call failed after ${retries} attempts.`);
            }

            // Calculate the exponential delay
            waitTime = initialDelay * Math.pow(2, attempt);
        }

        // Wait for the calculated time before retrying
        await new Promise(resolve => setTimeout(resolve, waitTime));

        apiCall(...args);
    };

    return retry;
};
