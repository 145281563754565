import { handleOfflineSurveys } from "@/utilities/offlineSurveys";
import { handleOfflineProperties } from "@/utilities/offlineProperties";
import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";

const maxRetries = 5;
const baseDelay = 2000;
let retryCount = 0;
let isPhotoSendingInProgress = false;

async function sendImageData() {
    const { sendPersistedImages } = useProperties();
    try {
        isPhotoSendingInProgress = true;
        console.log("Attempting to send image data.");

        await sendPersistedImages();
        console.log("Image data sent successfully.");

        isPhotoSendingInProgress = false;
        retryCount = 0;
    } catch (error) {
        if (retryCount < maxRetries) {
            retryCount++;
            const delay = baseDelay + retryCount * 1000; // Exponential backoff

            console.error(`Error sending image data. Retrying in ${delay}ms.`, error);
            setTimeout(() => sendImageData(), delay);
        } else {
            console.error('Max retries reached. Giving up.');
            retryCount = 0;
            isPhotoSendingInProgress = false;
        }
    }
}

export async function handleOfflineData() {
    const store = useStore();
    await handleOfflineProperties();
    await handleOfflineSurveys();

    if (store.getters["app/isOnline"] && store.getters["app/isSocketConnected"]) {
        if (!isPhotoSendingInProgress) {
            setTimeout(() => sendImageData(), baseDelay);
        }
    }
}


