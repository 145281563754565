import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bdfcab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-4" }
const _hoisted_2 = ["id", "data-cy"]
const _hoisted_3 = { class: "block lg:flex" }
const _hoisted_4 = { class: "flex lg:flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    "data-cy": "imageGalleryModal",
    title: $props.title,
    "hide-save": "",
    "go-back-mode": "",
    onClose: _cache[0] || (_cache[0] = () => $setup.cancel())
  }, {
    cta: _withCtx(() => [
      _createVNode(_component_ion_buttons, { class: "justify-end" }, {
        default: _withCtx(() => [
          ($props.downloadEnabled)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                "data-cy": "downloadResource",
                title: _ctx.$t('hzba.buttons.tooltips.downloadAllImages'),
                onClick: $setup.downloadResources
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: $setup.canShare ? $setup.share : $setup.download
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["title", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        ref: "content",
        "data-cy": "imageGalleryContent"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.images, (photo, key) => {
              return (_openBlock(), _createElementBlock("div", {
                id: `photo-${key}`,
                key: photo,
                class: "px-4 pt-4",
                "data-cy": `photo-${key}`
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_image, {
                    src: $setup.getPhotoUrl(photo),
                    "image-class": 'w-full h-auto',
                    "data-cy": `image-${key}`
                  }, null, 8, ["src", "data-cy"]),
                  _createElementVNode("div", _hoisted_4, [
                    ($props.onDelete && !$props.isReadonly)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          "data-cy": "button-delete-photo",
                          class: "lg:ml-2",
                          title: _ctx.$t('hzba.buttons.tooltips.deleteImage'),
                          onClick: ($event: any) => ($props.onDelete(photo))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: $setup.trashOutline
                            }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["title", "onClick"]))
                      : _createCommentVNode("", true),
                    ($props.downloadEnabled)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 1,
                          class: "lg:ml-2",
                          title: _ctx.$t('hzba.buttons.tooltips.downloadImage'),
                          onClick: () => $setup.downloadResource(photo)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: $setup.canShare ? $setup.share : $setup.download
                            }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["title", "onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 8, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["title"]))
}